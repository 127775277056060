import { createThirdwebClient } from "thirdweb";
import "./App.css";
import { ConnectButton } from "thirdweb/react";
import { createWallet } from "thirdweb/wallets";
import { useState, useEffect } from "react";
import { useActiveAccount } from "thirdweb/react";

const clientId = import.meta.env.VITE_CLIENT_ID;



if (!clientId) {
  throw new Error("Client ID not found. Please configure VITE_CLIENT_ID in your .env file");
}

const client = createThirdwebClient({
  clientId: clientId,
});
const API_URL = import.meta.env.VITE_API_URL || 'https://api.kalisinu.com';

const wallets = [
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("me.rainbow"),
  createWallet("io.zerion.wallet"),
  createWallet("com.trustwallet.app"),
];

interface EligibilityDetails {
  kalisBalance: string;
  kalisimuAmount: string;
  isRegistered: boolean;
  registrationInfo: {
    solanaAddress: string;
    timestamp: string;
  } | null;
}

interface EligibilityResponse {
  success: boolean;
  isEligible: boolean;
  details: EligibilityDetails | null;
  message?: string;
}

async function checkEligibilityAPI(address: string): Promise<EligibilityResponse> {
  const response = await fetch(`${API_URL}/api/check-eligibility`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ address })
  });
  
  if (!response.ok) {
    throw new Error('Server error during verification');
  }
  
  return response.json();
}

async function registerAPI(kalichainAddress: string, solanaAddress: string) {
  const response = await fetch(`${API_URL}/api/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ kalichainAddress, solanaAddress })
  });

  const data = await response.json();
  
  if (!response.ok) {
    throw new Error(data.message || 'Registration error');
  }
  
  return data;
}

function App() {
  // Déplacer tous les useState ici au début du composant
  const [isFocused, setIsFocused] = useState(false);
  const account = useActiveAccount();
  const [solanaAddress, setSolanaAddress] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [eligibility, setEligibility] = useState<EligibilityDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serverStatus, setServerStatus] = useState<'online' | 'offline' | 'checking'>('checking');

  // Déplacer inputStyle à l'intérieur du composant si vous voulez utiliser des états
  const inputStyle = {
    width: "100%",
    padding: "15px",
    borderRadius: "8px",
    border: "2px solid #757575",
    fontSize: "16px",
    transition: "all 0.3s ease",
    outline: "none",
    boxSizing: "border-box" as const,
    backgroundColor: "#333",
    color: "#fff"
  };
  // Check server status
  useEffect(() => {
    async function checkServer() {
      try {
        await fetch(API_URL);
        setServerStatus('online');
      } catch {
        setServerStatus('offline');
        setError("Server unavailable. Please try again later.");
      }
    }
    checkServer();
  }, []);

  // Check eligibility when wallet is connected
  useEffect(() => {
    async function checkEligibility() {
      if (!account?.address || serverStatus !== 'online') return;

      setIsLoading(true);
      setError("");

      try {
        const data = await checkEligibilityAPI(account.address);
        
        if (data.success && data.isEligible && data.details) {
          setEligibility(data.details);
          if (data.details.isRegistered && data.details.registrationInfo) {
            setIsSubmitted(true);
            setSolanaAddress(data.details.registrationInfo.solanaAddress);
          }
        } else {
          setError(data.message || "Not eligible for airdrop");
          setEligibility(null);
        }
      } catch (error: any) {
        console.error("Eligibility check error:", error);
        setError(error.message || "Error during verification");
        setEligibility(null);
      } finally {
        setIsLoading(false);
      }
    }

    checkEligibility();
  }, [account?.address, serverStatus]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!solanaAddress.trim() || !account?.address) return;

    setIsLoading(true);
    setError("");

    try {
      await registerAPI(account.address, solanaAddress.trim());
      setIsSubmitted(true);
      
      if (eligibility) {
        setEligibility({
          ...eligibility,
          isRegistered: true,
          registrationInfo: {
            solanaAddress: solanaAddress.trim(),
            timestamp: new Date().toISOString()
          }
        });
      }
    } catch (error: any) {
      console.error("Registration error:", error);
      setError(error.message || "Error during registration");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ 
      display: "flex", 
      flexDirection: "column", 
      justifyContent: "center", 
      alignItems: "center", 
      minHeight: "100vh",
      width: "100%",
      padding: "20px", 
      backgroundColor: "#2a2a2a",
      margin: "0 auto",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: "auto"
    }}>
      <div style={{
        maxWidth: "600px", 
        width: "100%", 
        padding: "20px",
        backgroundColor: "#2a2a2a",
        boxShadow: "0 0 20px rgba(0,0,0,0.1)",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto"
      }}>
        {/* Header with logo */}
        <div style={{
          textAlign: "center",
          marginBottom: "30px",
          padding: "20px 0"
        }}>
          <img 
          src="/assets/A.png"
          alt="KALISINU Logo"
          style={{
            width: "150px",
            height: "150px",
            marginBottom: "20px",
            borderRadius: "50%"
          }}
        />
          
          <h1 style={{ 
            fontSize: "32px", 
            fontWeight: "bold",
            background: "linear-gradient(45deg, #ff6f61, #ffa726)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            marginBottom: "10px"
          }}>
            KALISINU AIRDROP
          </h1>
          <p style={{
            color: "#d3d3d3",
            fontSize: "16px",
            maxWidth: "400px",
            margin: "0 auto"
          }}>
            Receive 3x your KALIS balance in KALISINU
          </p>
        </div>
        
        {/* Connect button in frame */}
        <div style={{
          background: "linear-gradient(135deg, #424242 0%, #616161 100%)",
          padding: "20px",
          borderRadius: "12px",
          marginBottom: "20px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
          width: "100%",
          textAlign: "center"
        }}>
          <ConnectButton
            client={client}
            wallets={wallets}
          />
        </div>

        {/* Server status message */}
        {serverStatus === 'offline' && (
          <div style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#ffccbc",
            color: "#d32f2f",
            borderRadius: "8px",
            border: "1px solid #ff8a65",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            textAlign: "center"
          }}>
            <span style={{ fontSize: "20px" }}>⚠️</span>
            <span>Server unavailable. Please try again later.</span>
          </div>
        )}

        {/* Loading indicator */}
        {isLoading && (
          <div style={{ 
            marginTop: "20px", 
            textAlign: "center",
            padding: "20px",
            background: "rgba(0,0,0,0.7)",
            borderRadius: "8px"
          }}>
            <div style={{
              border: "3px solid #bdbdbd",
              borderTop: "3px solid #ffa726",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              animation: "spin 1s linear infinite",
              margin: "0 auto",
              marginBottom: "10px"
            }} />
            <p style={{ color: "#d3d3d3" }}>Loading...</p>
          </div>
        )}

        {/* Error message */}
        {error && (
          <div style={{
            marginTop: "20px",
            padding: "15px",
            backgroundColor: "#ffcdd2",
            color: "#b71c1c",
            borderRadius: "8px",
            border: "1px solid #e57373",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            textAlign: "center"
          }}>
            <span style={{ fontSize: "20px" }}>❌</span>
            <span>{error}</span>
          </div>
        )}

        {/* Main section with eligibility information */}
        {account && eligibility && (
          <div style={{ 
            marginTop: "30px",
            animation: "fadeIn 0.5s ease-out",
            width: "100%",
            textAlign: "center"
          }}>
            <div style={{
              padding: "25px",
              background: "linear-gradient(135deg, #37474f 0%, #546e7a 100%)",
              borderRadius: "12px",
              marginBottom: "25px",
              boxShadow: "0 4px 15px rgba(0,0,0,0.3)"
            }}>
              <h3 style={{ 
                marginBottom: "15px", 
                color: "#ffcc80",
                fontSize: "22px",
                fontWeight: "600"
              }}>
                ✨ Eligible for Airdrop!
              </h3>
              <div style={{
                display: "grid",
                gap: "15px",
                background: "rgba(255,255,255,0.1)",
                padding: "15px",
                borderRadius: "8px"
              }}>
                <p style={{ 
                  fontSize: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>
                  <span>KALIS Balance:</span>
                  <strong>{eligibility.kalisBalance}</strong>
                </p>
                <p style={{ 
                  fontSize: "18px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#ffab40",
                  fontWeight: "bold"
                }}>
                  <span>KALISINU to receive:</span>
                  <strong>{eligibility.kalisimuAmount}</strong>
                </p>
              </div>
            </div>

            {/* Form or confirmation */}
            {!isSubmitted ? (
              <form onSubmit={handleSubmit} style={{ 
                marginTop: "25px",
                padding: "25px",
                backgroundColor: "#424242",
                borderRadius: "12px",
                boxShadow: "0 4px 15px rgba(0,0,0,0.3)",
                border: "1px solid #757575",
                width: "100%",
                textAlign: "center",
                // Ajout de box-sizing
                boxSizing: "border-box"
              }}>
                <div style={{ marginBottom: "20px", width: "100%" }}>
                  <label style={{ 
                    display: "block", 
                    marginBottom: "10px",
                    fontWeight: "500",
                    color: "#e0e0e0",
                    fontSize: "16px"
                  }}>
                    Your Solana address to receive the airdrop:
                  </label>
                  <input
                  type="text"
                  value={solanaAddress}
                  onChange={(e) => setSolanaAddress(e.target.value)}
                  style={{
                    ...inputStyle,
                    borderColor: isFocused ? "#ffa726" : "#757575",
                    boxShadow: isFocused ? "0 0 0 2px rgba(255, 167, 38, 0.2)" : "none"
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  placeholder="Enter your Solana address"
                  required
                  disabled={isLoading}
                />
                </div>
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "15px",
                    backgroundColor: "#ffa726",
                    color: "#212121",
                    border: "none",
                    borderRadius: "8px",
                    cursor: isLoading ? "not-allowed" : "pointer",
                    opacity: isLoading ? 0.7 : 1,
                    fontSize: "16px",
                    fontWeight: "600",
                    transition: "all 0.3s ease",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.4)"
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? "Registering..." : "Confirm"}
                </button>
              </form>
            ) : (
              <div style={{ 
                marginTop: "25px",
                padding: "25px",
                background: "linear-gradient(135deg, #455a64 0%, #607d8b 100%)",
                borderRadius: "12px",
                border: "2px solid #78909c",
                boxShadow: "0 4px 15px rgba(0,0,0,0.3)",
                animation: "fadeIn 0.5s ease-out",
                textAlign: "center"
              }}>
                <h3 style={{ 
                  color: "#ffcc80", 
                  marginBottom: "20px",
                  fontSize: "22px",
                  fontWeight: "600"
                }}>
                  🎉 Registration Confirmed!
                </h3>
                <div>
                  <p style={{ 
                    color: "#ffcc80",
                    marginBottom: "10px",
                    fontSize: "16px"
                  }}>
                    Registered Solana Address:
                  </p>
                  <p style={{ 
                    fontFamily: "monospace",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    padding: "15px",
                    borderRadius: "8px",
                    marginTop: "10px",
                    wordBreak: "break-all",
                    border: "1px solid #90a4ae"
                  }}>
                    {solanaAddress}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;